/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDeliberaCoordinatoreAttivitaTrasversaleRequest } from '../model/addDeliberaCoordinatoreAttivitaTrasversaleRequest';
import { AddDocumentoDottorandoAttivitaTrasversaleRequest } from '../model/addDocumentoDottorandoAttivitaTrasversaleRequest';
import { CorsoPianoDiStudiSuperamentoRequest } from '../model/corsoPianoDiStudiSuperamentoRequest';
import { EsitoRichiestaApprovazioneAttivitaExtra } from '../model/esitoRichiestaApprovazioneAttivitaExtra';
import { EsitoRichiestaApprovazionePianoDiStudi } from '../model/esitoRichiestaApprovazionePianoDiStudi';
import { EsitoRichiestaApprovazionePianoDiStudiByCoordinatore } from '../model/esitoRichiestaApprovazionePianoDiStudiByCoordinatore';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest } from '../model/getUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest';
import { GetUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse } from '../model/getUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse';
import { PianoDiStudiInfoView } from '../model/pianoDiStudiInfoView';
import { PianoDiStudiInfoViewImpl } from '../model/pianoDiStudiInfoViewImpl';
import { PianoDiStudiStoriciSbloccoInfoView } from '../model/pianoDiStudiStoriciSbloccoInfoView';
import { RichiestaSuperamentoAttivitaTrasversaleRequest } from '../model/richiestaSuperamentoAttivitaTrasversaleRequest';
import { SuperamentoAttivitaTrasversalePianoDiStudiRequest } from '../model/superamentoAttivitaTrasversalePianoDiStudiRequest';
import { VerificaDocumentoDorrorandoAttivitaTrasversaleRequest } from '../model/verificaDocumentoDorrorandoAttivitaTrasversaleRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PianiDiStudiService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette al coordinatore di aggiungere una delibera per una attività trasversale di un piano di studi.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeliberaCoordinatoreAttivitaTrasversale(body: AddDeliberaCoordinatoreAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public addDeliberaCoordinatoreAttivitaTrasversale(body: AddDeliberaCoordinatoreAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public addDeliberaCoordinatoreAttivitaTrasversale(body: AddDeliberaCoordinatoreAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public addDeliberaCoordinatoreAttivitaTrasversale(body: AddDeliberaCoordinatoreAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDeliberaCoordinatoreAttivitaTrasversale.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling addDeliberaCoordinatoreAttivitaTrasversale.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoView>('put',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/add-delibera`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di aggiungere un documento richiesto per una attività trasversale di un piano di studi.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param addDocumentoDottorandoAttivitaTrasversaleRequest 
     * @param documentoDottorando 
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDocumentoDottorandoAttivitaTrasversaleForm(id_piano_di_studi: string, addDocumentoDottorandoAttivitaTrasversaleRequest?: AddDocumentoDottorandoAttivitaTrasversaleRequest, documentoDottorando?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public addDocumentoDottorandoAttivitaTrasversaleForm(id_piano_di_studi: string, addDocumentoDottorandoAttivitaTrasversaleRequest?: AddDocumentoDottorandoAttivitaTrasversaleRequest, documentoDottorando?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public addDocumentoDottorandoAttivitaTrasversaleForm(id_piano_di_studi: string, addDocumentoDottorandoAttivitaTrasversaleRequest?: AddDocumentoDottorandoAttivitaTrasversaleRequest, documentoDottorando?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public addDocumentoDottorandoAttivitaTrasversaleForm(id_piano_di_studi: string, addDocumentoDottorandoAttivitaTrasversaleRequest?: AddDocumentoDottorandoAttivitaTrasversaleRequest, documentoDottorando?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling addDocumentoDottorandoAttivitaTrasversale.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addDocumentoDottorandoAttivitaTrasversaleRequest !== undefined) {
            formParams = formParams.append('addDocumentoDottorandoAttivitaTrasversaleRequest', new Blob([JSON.stringify(addDocumentoDottorandoAttivitaTrasversaleRequest)], { type: 'application/json'})) as any || formParams;
        }
        if (documentoDottorando !== undefined) {
            formParams = formParams.append('documentoDottorando', <any>documentoDottorando) as any || formParams;
        }

        return this.httpClient.request<PianoDiStudiInfoView>('put',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/add-documento-dottorando`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore di aggiungere un documento richiesto per il superamento di una attività trasversale di un piano di studi.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param superamentoAttivitaTrasversalePianoDiStudiRequest 
     * @param documentoSupervisore 
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDocumentoSupervisoreAttivitaTrasversaleForm(id_piano_di_studi: string, superamentoAttivitaTrasversalePianoDiStudiRequest?: SuperamentoAttivitaTrasversalePianoDiStudiRequest, documentoSupervisore?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public addDocumentoSupervisoreAttivitaTrasversaleForm(id_piano_di_studi: string, superamentoAttivitaTrasversalePianoDiStudiRequest?: SuperamentoAttivitaTrasversalePianoDiStudiRequest, documentoSupervisore?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public addDocumentoSupervisoreAttivitaTrasversaleForm(id_piano_di_studi: string, superamentoAttivitaTrasversalePianoDiStudiRequest?: SuperamentoAttivitaTrasversalePianoDiStudiRequest, documentoSupervisore?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public addDocumentoSupervisoreAttivitaTrasversaleForm(id_piano_di_studi: string, superamentoAttivitaTrasversalePianoDiStudiRequest?: SuperamentoAttivitaTrasversalePianoDiStudiRequest, documentoSupervisore?: Blob, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling addDocumentoSupervisoreAttivitaTrasversale.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (superamentoAttivitaTrasversalePianoDiStudiRequest !== undefined) {
            formParams = formParams.append('superamentoAttivitaTrasversalePianoDiStudiRequest', new Blob([JSON.stringify(superamentoAttivitaTrasversalePianoDiStudiRequest)], { type: 'application/json'})) as any || formParams;
        }
        if (documentoSupervisore !== undefined) {
            formParams = formParams.append('documentoSupervisore', <any>documentoSupervisore) as any || formParams;
        }

        return this.httpClient.request<PianoDiStudiInfoView>('put',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/add-documento-supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approvazione o Rifiuto di un aggiornamento di un piano di studi da un Coordinatore.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param idAggiornamento id dell&#x27;aggiornamento piano di studi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvaRifiutaAggiornamentoByCoordinatore(body: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore, id_piano_di_studi: string, idAggiornamento: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public approvaRifiutaAggiornamentoByCoordinatore(body: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore, id_piano_di_studi: string, idAggiornamento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public approvaRifiutaAggiornamentoByCoordinatore(body: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore, id_piano_di_studi: string, idAggiornamento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public approvaRifiutaAggiornamentoByCoordinatore(body: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore, id_piano_di_studi: string, idAggiornamento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling approvaRifiutaAggiornamentoByCoordinatore.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling approvaRifiutaAggiornamentoByCoordinatore.');
        }

        if (idAggiornamento === null || idAggiornamento === undefined) {
            throw new Error('Required parameter idAggiornamento was null or undefined when calling approvaRifiutaAggiornamentoByCoordinatore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/aggiornamento/${encodeURIComponent(String(idAggiornamento))}/coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approvazione o Rifiuto di un aggiornamento di un piano di studi da un Supervisore.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param idAggiornamento id dell&#x27;aggiornamento piano di studi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvaRifiutaAggiornamentoBySupervisore(body: EsitoRichiestaApprovazionePianoDiStudi, id_piano_di_studi: string, idAggiornamento: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public approvaRifiutaAggiornamentoBySupervisore(body: EsitoRichiestaApprovazionePianoDiStudi, id_piano_di_studi: string, idAggiornamento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public approvaRifiutaAggiornamentoBySupervisore(body: EsitoRichiestaApprovazionePianoDiStudi, id_piano_di_studi: string, idAggiornamento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public approvaRifiutaAggiornamentoBySupervisore(body: EsitoRichiestaApprovazionePianoDiStudi, id_piano_di_studi: string, idAggiornamento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling approvaRifiutaAggiornamentoBySupervisore.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling approvaRifiutaAggiornamentoBySupervisore.');
        }

        if (idAggiornamento === null || idAggiornamento === undefined) {
            throw new Error('Required parameter idAggiornamento was null or undefined when calling approvaRifiutaAggiornamentoBySupervisore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/aggiornamento/${encodeURIComponent(String(idAggiornamento))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nel corso del piano di studi di uno studente.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param id_corso_in_offerta_formativa id del corso in offerta formativa
     * @param id_categoria_in_offerta_formativa nome del file allegato da scaricare
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoAttivitaExtraPianoDiStudi(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, id_categoria_in_offerta_formativa: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoAttivitaExtraPianoDiStudi(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, id_categoria_in_offerta_formativa: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoAttivitaExtraPianoDiStudi(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, id_categoria_in_offerta_formativa: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoAttivitaExtraPianoDiStudi(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, id_categoria_in_offerta_formativa: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling getAllegatoAttivitaExtraPianoDiStudi.');
        }

        if (id_corso_in_offerta_formativa === null || id_corso_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_corso_in_offerta_formativa was null or undefined when calling getAllegatoAttivitaExtraPianoDiStudi.');
        }

        if (id_categoria_in_offerta_formativa === null || id_categoria_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_categoria_in_offerta_formativa was null or undefined when calling getAllegatoAttivitaExtraPianoDiStudi.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoAttivitaExtraPianoDiStudi.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_categoria_in_offerta_formativa !== undefined && id_categoria_in_offerta_formativa !== null) {
            queryParameters = queryParameters.set('id_categoria_in_offerta_formativa', <any>id_categoria_in_offerta_formativa);
        }
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-extra/${encodeURIComponent(String(id_corso_in_offerta_formativa))}/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nell&#x27;Attività Trasversale del piano di studi di uno studente.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param id_categoria_offerta_formativa id della categoria associata all&#x27;attività trasversale
     * @param nome_file nome del file allegato da scaricare
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoAttivitaTrasversalePianoDiStudi(id_piano_di_studi: string, id_categoria_offerta_formativa: string, nome_file: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoAttivitaTrasversalePianoDiStudi(id_piano_di_studi: string, id_categoria_offerta_formativa: string, nome_file: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoAttivitaTrasversalePianoDiStudi(id_piano_di_studi: string, id_categoria_offerta_formativa: string, nome_file: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoAttivitaTrasversalePianoDiStudi(id_piano_di_studi: string, id_categoria_offerta_formativa: string, nome_file: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling getAllegatoAttivitaTrasversalePianoDiStudi.');
        }

        if (id_categoria_offerta_formativa === null || id_categoria_offerta_formativa === undefined) {
            throw new Error('Required parameter id_categoria_offerta_formativa was null or undefined when calling getAllegatoAttivitaTrasversalePianoDiStudi.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoAttivitaTrasversalePianoDiStudi.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }
        if (id_categoria_offerta_formativa !== undefined && id_categoria_offerta_formativa !== null) {
            queryParameters = queryParameters.set('id_categoria_offerta_formativa', <any>id_categoria_offerta_formativa);
        }
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di scaricare un pdf con l&#x27;ultimo aggiornamento dei piani di studi a partire da una lista di id utente.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/piano-di-studi/get-ultimi-aggiornamenti-piani/pdf`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli storici di sblocco di presentazione e modifica del piano di studi di un dottorando.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoriciSbloccoPiano(id_piano_di_studi: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiStoriciSbloccoInfoView>;
    public getStoriciSbloccoPiano(id_piano_di_studi: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiStoriciSbloccoInfoView>>;
    public getStoriciSbloccoPiano(id_piano_di_studi: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiStoriciSbloccoInfoView>>;
    public getStoriciSbloccoPiano(id_piano_di_studi: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling getStoriciSbloccoPiano.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PianoDiStudiStoriciSbloccoInfoView>('get',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/get-storici-sblocco-piano`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di cercare l&#x27;ultimo aggiornamento dei piani di studi a partire da una lista di id utente.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<GetUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse>;
    public getUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse>>;
    public getUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse>>;
    public getUltimiAggiornamentiPianiDiStudiByUtenteIds(body: GetUltimiAggiornamentiPianiDiStudiByUtenteIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getUltimiAggiornamentiPianiDiStudiByUtenteIds.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetUltimiAggiornamentiPianiDiStudiByUtenteIdsResponse>('post',`${this.basePath}/api/v1/piano-di-studi/get-ultimi-aggiornamenti-piani`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare il superamento di un attività trasversale di un piano di studi.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registraSuperamentoAttivitaTrasversalePianoDiStudi(body: SuperamentoAttivitaTrasversalePianoDiStudiRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public registraSuperamentoAttivitaTrasversalePianoDiStudi(body: SuperamentoAttivitaTrasversalePianoDiStudiRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public registraSuperamentoAttivitaTrasversalePianoDiStudi(body: SuperamentoAttivitaTrasversalePianoDiStudiRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public registraSuperamentoAttivitaTrasversalePianoDiStudi(body: SuperamentoAttivitaTrasversalePianoDiStudiRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registraSuperamentoAttivitaTrasversalePianoDiStudi.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling registraSuperamentoAttivitaTrasversalePianoDiStudi.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/superato`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare il superamento di un corso di un piano di studi.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param id_corso_in_offerta_formativa id del corso in offerta formativa
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registraSuperamentoCorsoPianoDiStudi(body: CorsoPianoDiStudiSuperamentoRequest, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public registraSuperamentoCorsoPianoDiStudi(body: CorsoPianoDiStudiSuperamentoRequest, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public registraSuperamentoCorsoPianoDiStudi(body: CorsoPianoDiStudiSuperamentoRequest, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public registraSuperamentoCorsoPianoDiStudi(body: CorsoPianoDiStudiSuperamentoRequest, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registraSuperamentoCorsoPianoDiStudi.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling registraSuperamentoCorsoPianoDiStudi.');
        }

        if (id_corso_in_offerta_formativa === null || id_corso_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_corso_in_offerta_formativa was null or undefined when calling registraSuperamentoCorsoPianoDiStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/corso/${encodeURIComponent(String(id_corso_in_offerta_formativa))}/superato`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette allo studente di richiedere l&#x27;approvazione di una attività extra di un piano di studi.
     * 
     * @param id_categoria_in_offerta_formativa id della categoria del corso in offerta formativa
     * @param id_piano_di_studi id del piano di studi
     * @param id_corso_in_offerta_formativa id del corso in offerta formativa
     * @param attestatoAttivitaExtra 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediApprovazioneAttivitaExtraPianoDiStudiForm(id_categoria_in_offerta_formativa: string, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, attestatoAttivitaExtra?: Blob, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public richiediApprovazioneAttivitaExtraPianoDiStudiForm(id_categoria_in_offerta_formativa: string, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, attestatoAttivitaExtra?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public richiediApprovazioneAttivitaExtraPianoDiStudiForm(id_categoria_in_offerta_formativa: string, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, attestatoAttivitaExtra?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public richiediApprovazioneAttivitaExtraPianoDiStudiForm(id_categoria_in_offerta_formativa: string, id_piano_di_studi: string, id_corso_in_offerta_formativa: string, attestatoAttivitaExtra?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_categoria_in_offerta_formativa === null || id_categoria_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_categoria_in_offerta_formativa was null or undefined when calling richiediApprovazioneAttivitaExtraPianoDiStudi.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling richiediApprovazioneAttivitaExtraPianoDiStudi.');
        }

        if (id_corso_in_offerta_formativa === null || id_corso_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_corso_in_offerta_formativa was null or undefined when calling richiediApprovazioneAttivitaExtraPianoDiStudi.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_categoria_in_offerta_formativa !== undefined && id_categoria_in_offerta_formativa !== null) {
            queryParameters = queryParameters.set('id_categoria_in_offerta_formativa', <any>id_categoria_in_offerta_formativa);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attestatoAttivitaExtra !== undefined) {
            formParams = formParams.append('attestatoAttivitaExtra', <any>attestatoAttivitaExtra) as any || formParams;
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-extra/${encodeURIComponent(String(id_corso_in_offerta_formativa))}/richiedi-approvazione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di richiedere il superamento di una attività trasversale di un piano di studi.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediSuperamentoAttivitaTrasversale(body: RichiestaSuperamentoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public richiediSuperamentoAttivitaTrasversale(body: RichiestaSuperamentoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public richiediSuperamentoAttivitaTrasversale(body: RichiestaSuperamentoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public richiediSuperamentoAttivitaTrasversale(body: RichiestaSuperamentoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling richiediSuperamentoAttivitaTrasversale.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling richiediSuperamentoAttivitaTrasversale.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/richiedi-superamento`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di sbloccare o revocare lo sblocco per la presentazione del piano formativo a un dottorando.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param revoca_sblocco revoca sblocco
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sbloccaPresentazionePianoDiStudi(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public sbloccaPresentazionePianoDiStudi(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public sbloccaPresentazionePianoDiStudi(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public sbloccaPresentazionePianoDiStudi(id_piano_di_studi: string, revoca_sblocco?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling sbloccaPresentazionePianoDiStudi.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (revoca_sblocco !== undefined && revoca_sblocco !== null) {
            queryParameters = queryParameters.set('revoca_sblocco', <any>revoca_sblocco);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/sblocca-presentazione-piano`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di sbloccare o revocare lo sblocco per la sottomissione del piano formativo a un dottorando anche se non rispetta i vincoli dell&#x27;offerta formativa e delle categorie.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param revoca_sblocco revoca sblocco
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sbloccaSottomissionePianoDiStudiNonValido(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public sbloccaSottomissionePianoDiStudiNonValido(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public sbloccaSottomissionePianoDiStudiNonValido(id_piano_di_studi: string, revoca_sblocco?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public sbloccaSottomissionePianoDiStudiNonValido(id_piano_di_studi: string, revoca_sblocco?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling sbloccaSottomissionePianoDiStudiNonValido.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (revoca_sblocco !== undefined && revoca_sblocco !== null) {
            queryParameters = queryParameters.set('revoca_sblocco', <any>revoca_sblocco);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/sblocca-sottomissione-piano-non-valido`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore di approvare o rifiutare il documento del dottorando di una attività trasversale di un piano di studi.
     * 
     * @param body 
     * @param id_piano_di_studi id del piano di studi
     * @param id_attivita_trasversale id dell&#x27;attività trasversale
     * @param id_attivita_presunta id dell&#x27;attività presunta
     * @param id_attivita_proposta id dell&#x27;attività proposta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaDocumentoDottorandoAttivitaTrasversale(body: VerificaDocumentoDorrorandoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public verificaDocumentoDottorandoAttivitaTrasversale(body: VerificaDocumentoDorrorandoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public verificaDocumentoDottorandoAttivitaTrasversale(body: VerificaDocumentoDorrorandoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public verificaDocumentoDottorandoAttivitaTrasversale(body: VerificaDocumentoDorrorandoAttivitaTrasversaleRequest, id_piano_di_studi: string, id_attivita_trasversale?: string, id_attivita_presunta?: string, id_attivita_proposta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaDocumentoDottorandoAttivitaTrasversale.');
        }

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling verificaDocumentoDottorandoAttivitaTrasversale.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_attivita_trasversale !== undefined && id_attivita_trasversale !== null) {
            queryParameters = queryParameters.set('id_attivita_trasversale', <any>id_attivita_trasversale);
        }
        if (id_attivita_presunta !== undefined && id_attivita_presunta !== null) {
            queryParameters = queryParameters.set('id_attivita_presunta', <any>id_attivita_presunta);
        }
        if (id_attivita_proposta !== undefined && id_attivita_proposta !== null) {
            queryParameters = queryParameters.set('id_attivita_proposta', <any>id_attivita_proposta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-trasversale/verifica-documento-dottorando`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore di verificare una richiesta di approvazione attività extra di un piano di studi.
     * 
     * @param id_piano_di_studi id del piano di studi
     * @param id_corso_in_offerta_formativa id del corso in offerta formativa
     * @param esito 
     * @param deliberaAttivitaExtra 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, esito?: EsitoRichiestaApprovazioneAttivitaExtra, deliberaAttivitaExtra?: Blob, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoView>;
    public verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, esito?: EsitoRichiestaApprovazioneAttivitaExtra, deliberaAttivitaExtra?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoView>>;
    public verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, esito?: EsitoRichiestaApprovazioneAttivitaExtra, deliberaAttivitaExtra?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoView>>;
    public verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(id_piano_di_studi: string, id_corso_in_offerta_formativa: string, esito?: EsitoRichiestaApprovazioneAttivitaExtra, deliberaAttivitaExtra?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_piano_di_studi === null || id_piano_di_studi === undefined) {
            throw new Error('Required parameter id_piano_di_studi was null or undefined when calling verificaRichiestaApprovazioneAttivitaExtraPianoDiStudi.');
        }

        if (id_corso_in_offerta_formativa === null || id_corso_in_offerta_formativa === undefined) {
            throw new Error('Required parameter id_corso_in_offerta_formativa was null or undefined when calling verificaRichiestaApprovazioneAttivitaExtraPianoDiStudi.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esito !== undefined) {
            formParams = formParams.append('esito', new Blob([JSON.stringify(esito)], { type: 'application/json'})) as any || formParams;
        }
        if (deliberaAttivitaExtra !== undefined) {
            formParams = formParams.append('deliberaAttivitaExtra', <any>deliberaAttivitaExtra) as any || formParams;
        }

        return this.httpClient.request<PianoDiStudiInfoView>('post',`${this.basePath}/api/v1/piano-di-studi/${encodeURIComponent(String(id_piano_di_studi))}/attivita-extra/${encodeURIComponent(String(id_corso_in_offerta_formativa))}/verifica-approvazione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
