<ng-container *transloco="let t ">
    <div class="h-full w-full" fxLayout="column">
        <mat-drawer-container class="flex-auto h-full" style="overflow: hidden">
            <!-- Drawer -->
            <mat-drawer #drawer style="overflow: hidden"
                        class="w-72 sm:w-180 dark:bg-gray-900 my-mat-drawer"
                        mode='over'
                        [opened]="drawerOpened"
                        [autoFocus]="false">

                <app-mobilita-sidebar-filter-container #mobilitaSidebarContainer
                                                       *ngIf="selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO"
                                                       [hideStatus]="approvalMode && periodsApprovalMode"
                                                       [hideStatusRetribution]="approvalMode && validationConclusionApprovalMode"
                                                       style="overflow: hidden"
                                                       (closeDrawer)="closeDrawer()">
                </app-mobilita-sidebar-filter-container>

                <app-ricerca-sidebar-filter-container #ricercaSidebarContainer
                                                      *ngIf="selectedTabTipo === TipoMobilitaDashboardTabRequest.RICERCA"
                                                      [hideStatus]="approvalMode"
                                                      style="overflow: hidden"
                                                      (closeDrawer)="closeDrawer()">
                </app-ricerca-sidebar-filter-container>


                <app-autorizzazioni-sidebar-filter-container-request #autorizzazioniSidebarContainer
                                                                     *ngIf="selectedTabTipo === TipoMobilitaDashboardTabRequest.AUTORIZZAZIONI"
                                                                     style="overflow: hidden"
                                                                     [hideStatus]="approvalMode"
                                                                     (closeDrawer)="closeDrawer()">
                </app-autorizzazioni-sidebar-filter-container-request>

            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col overflow-hidden">
                <app-wrap-request
                    [titleHeader]="'sidebar.mobility'"
                    (resetStep$)="resetStep()"
                    (changeRuolo$)="changeRuolo()"
                    [getCurrentProfileCanAccess]="getCurrentProfileCanAccess()"
                    (canRenderContent)="canRenderContent = $event"
                    [noProfileSelectedLabel]="'requests.no_profile_selected_mobilita_ricerca'"
                    [profileNotAdmittedLabel]="'requests.profile_not_admitted_mobilita_ricerca'"
                    [approvalMode]="approvalMode"
                    class="h-full w-full">

                    <ng-container *ngIf="canRenderContent && approvalMode">
                        <app-operazioni-massive
                            class="w-full h-full"
                            [operazioneMassivaTipo]="operazioneMassivaTipo"
                            [tornaAllaListaTmpl]="button">
                        </app-operazioni-massive>

                    </ng-container>

                    <ng-container *ngIf="canRenderContent && !approvalMode">
                        <div class="w-full h-full bg-card shadow px-2 py-6">
                            <app-tabs-periodi-mobilita-ricerca
                                (resetFilterApplied$)="reset($event)"
                                (drawer$)="openDrawer($event)"
                                (selectTab$)="changeTab($event)"
                                [isFirstTime]="isFirstTime"
                                [selectedTabTipo]="selectedTabTipo"
                                [selectValuesMobilita]="selectValuesMobilita"
                                [selectValuesRicerca]="selectValuesRicerca"
                                [approvalMode]="approvalMode"
                                [currentProfile]="currentProfile"
                                (setOperazioneMassiva)="setOperazioneMassiva($event)">

                            </app-tabs-periodi-mobilita-ricerca>
                        </div>

                    </ng-container>

                </app-wrap-request>

            </mat-drawer-content>
        </mat-drawer-container>

    </div>

    <ng-template #button>
        <button
            mat-flat-button
            *ngIf="currentProfile === AuthorityType.COORDINATORE"
            (click)=" setOperazioneMassiva()"
            [color]="'primary'">
            <mat-icon class="mr-2">{{ approvalMode ? 'view_list' : 'check_circle' }}</mat-icon>
            <span>{{ t(!approvalMode ?  this.selectedTabTipo === TipoMobilitaDashboardTabRequest.AUTORIZZAZIONI ? 'common.authorizes_phd_students' : 'common.approval_mode_text' : 'common.no_approval_mode_text') }}</span>
        </button>
<!--        <button
            mat-flat-button
            *ngIf="!approvalMode && currentProfile === AuthorityType.COORDINATORE && selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO"
            (click)=" setOperazioneMassiva(true)"
            [color]="'primary'">
            <mat-icon class="mr-2">{{ approvalMode ? 'view_list' : 'check_circle' }}</mat-icon>
            <span>{{ t('common.validation_approval_mode_text') }}</span>
        </button>-->
    </ng-template>
</ng-container>
