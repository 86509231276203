import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AggiornamentiBudgetStudenteService,
    AggiornamentoBudgetStudentePerOpMassivaInfoView,
    AggiornamentoBudgetStudenteStatus,
    AggiornamentoPianoDiStudiStatus,
    BudgetStudenteInfoView,
    CorsoPianoDiStudiPerExportViewImpl,
    EsitoAggiornamentoBudgetByGruppoPTA,
    MediaService,
    PageAggiornamentoBudgetStudentePerOpMassivaInfoView,
    SpesaStudenteStatus,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, map, Observable, ObservedValueOf, of, OperatorFunction, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {
    SpeseBarFilterContainerComponent
} from "../../../../../shared/components/spese-bar-filter-container/spese-bar-filter-container.component";
import {ApprovazioneModificheBudgetMassivaDataI} from "../approvazione-modifiche-budget-steps-interface";
import {CAN_GO_AHEAD$, DEFAULT_PAGE_SIZE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {annoRiferimentoToRomanNumeral, removeUnderscore} from "../../../../../shared/utils/utils";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    StudyPlanWithDeliberaMode
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {buildConfigurationForEditBudgetRequest} from "../../../pta-requests/pta-requests-utils";
import {
    BudgetEditFilters,
    ModificaBudgetFilterService
} from "../../../pta-requests/approvazione-modifica-budget/modifica-budget-sidebar-filter-container-request/modifica-budget-filter.service";
import {geCurrencyEuro} from "../../../../../shared/pipe/currency-euro.pipe";


@Component({
    selector: 'app-scegli-modifiche-budget-stepper',
    templateUrl: './scegli-modifiche-budget.component.html',
    styleUrls: ['./scegli-modifiche-budget.component.scss']
})
export class ScegliModificheBudgetComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean}>();

    loading: boolean;
    modificheBudgetConfiguration: GenericTableConfigurationModel;
    private corso: any;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: SpeseBarFilterContainerComponent;
    showTopbar: boolean;
    idRigheDaDisabilitare: string[] = [];

    @ViewChild(SpeseBarFilterContainerComponent) set speseBarFilterContainer(component: SpeseBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private logOutService: LogoutService,
                private filterManagerService: ModificaBudgetFilterService,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                private appInitService: AppInitService,
                private router: Router,
                private aggiornamentiBudgetStudenteService: AggiornamentiBudgetStudenteService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneModificheBudgetMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.isFirstTime = true;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            if(inputOutputData.refreshData) {
                console.log('REFRESHING SPESE IN SCEGLI SPESE')
                this.modificheBudgetConfiguration = undefined;
                this.idRigheDaDisabilitare = [];
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getModificheBudgetRequest$(false, this.filterManagerService?.filterApplied);
            }
        });
    }

    openFilters(){
        this.filterManagerService.openDrawer.next(true)
    }

    getModificheBudgetRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): void {
        this.getSpeseFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getModificheBudgetRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getSpeseFormObs$(isFirstTime?: boolean, filters?: BudgetEditFilters, page: number = 0, size: number = 10): Observable<PageAggiornamentoBudgetStudentePerOpMassivaInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.aggiornamentiBudgetStudenteService.searchAggiornamentiBudgetStudenteForm(
            !!filters?.nominativoStudente ? filters?.nominativoStudente : undefined,
            !!filters?.cfStudente ? filters?.cfStudente : undefined,
            !!filters?.matricolaStudente ? filters?.matricolaStudente : undefined,
            !!filters?.motivazioneModifica ? filters?.motivazioneModifica : undefined,
            [AggiornamentoBudgetStudenteStatus.DAAPPROVARE],
            !!filters?.annoRiferimento ? filters?.annoRiferimento : undefined,
            page, size, undefined).pipe(
            tap((pageModifiche: PageAggiornamentoBudgetStudentePerOpMassivaInfoView) => {
                this.modificheBudgetConfiguration = buildConfigurationForEditBudgetRequest(pageModifiche, size, page, true);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.REFUSE_PTA:
                this.tryOpenRefuseDialog($event);
                break;
            case TipoClickEnum.GO_TO:
                const spesa: AggiornamentoBudgetStudentePerOpMassivaInfoView = $event?.value;
                const url = this.router.serializeUrl(this.router.createUrlTree([PathEnum.STUDENTS, spesa.budgetStudente?.studenteCiclo?.utente?.id, PathEnum.BUDGET], {
                    fragment: annoRiferimentoToRomanNumeral(spesa?.annoRiferimento)+'-anno'
                }));
                window.open(url, '_blank');
                break;
        }
    }

    private tryOpenRefuseDialog(event: ClickEvent) {
        if(this.operazioneMassivaData$.getValue().budgetSelected?.find(
            s => s.id === event.value.id)){
            this.openDeselectFirstDialog();
        } else {
            this.openRefuseDialog(event)
        }
    }

    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
            {
                color: 'accent',
                label: get(translation, 'common.close', null), icon: 'close',
            },
        ]
        });
    }


    private openRefuseDialog(event: ClickEvent) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const budget = event.value as AggiornamentoBudgetStudentePerOpMassivaInfoView;
        const title =  get(translation, 'budget.refuse_budget', null);
        const message = get(translation, 'budget.refuse_approve_budget', null)
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: title + ' - ' + annoRiferimentoToRomanNumeral(budget.annoRiferimento) + ' ' + get(translation, 'common.year', null),
            message: get(translation, 'budget.approve_budget_msg', null) + geCurrencyEuro(budget.importo) + '. ' + message,
            icon: {
                name: 'mat_outline:cancel',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.refuseBudgetRequest(form, budget, form.getRawValue().motivo, dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'motivo',
                    transloco: 'budget.refuse_reason',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                motivo: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private refuseBudgetRequest(form, modifica: AggiornamentoBudgetStudentePerOpMassivaInfoView, motivo: string, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const esito: EsitoAggiornamentoBudgetByGruppoPTA = {
            approvato: false,
            nota: motivo,
        };
        form.disable();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA(
                esito, modifica?.budgetStudente?.studenteCiclo?.utente?.id, modifica.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                form.enable();
            })
        ).subscribe({
            next: (budget: BudgetStudenteInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.saved', null),
                    type: SnackbarTypes.Success,
                });
                this.idRigheDaDisabilitare = [...(this.idRigheDaDisabilitare ?? []), modifica.id];
                dialogRef.close()
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.error_saving', null),
                    error: err, type: SnackbarTypes.Error,
                });
            }
        });
    }


    private openInfoStudent(studente: CorsoPianoDiStudiPerExportViewImpl) {
        if (!!studente.urlImmagineProfilo && !!studente.studenteCodiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.urlImmagineProfilo, studente.studenteCodiceFiscale)
                .pipe(
                    map((image) => {
                        const objectURL = URL.createObjectURL(image);
                        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.openStudenteInfoDialog(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.openStudenteInfoDialog(studente);
        }
    }

    private openStudenteInfoDialog(studente: CorsoPianoDiStudiPerExportViewImpl, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.utenteCognome || '') + ' ' + (studente.utenteNome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utenteCognome?.charAt(0) || '') + (studente?.utenteNome.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utenteCognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utenteNome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.utenteMail?.toLowerCase()
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utenteTelefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.studenteCodiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.studenteMatricola
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }


    onSpeseDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            budgetSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as AggiornamentoBudgetStudentePerOpMassivaInfoView[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getModificheBudgetRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInSpeseList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)?.filter(item => item[0] !== 'status');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {
                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }

    resetFilters(): void {
        this.filterManagerService.restore();
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    private getDataAndFilterSubscribe() {
        if(this.isFirstTime){
            this.getModificheBudgetRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
        }

        this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            switchMap(value => {
                if(!this.isFirstTime){
                    return this.getSpeseFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize).pipe(
                        this.catchErrorInSpeseList$(this.getSpeseFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;
}
